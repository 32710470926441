 const data=[
    {
        title:"Director",
        role:"director",
        fullname:"Tewelde Adhanom",
        email:"Info@agoraconsulting-et.com",
        phonenumber:"+251-909-73-74-75",
        Address:"Grace Plaza 4th Floor, Bole, Addis Ababa",
        website:"https://agoraconsulting-et.com/",
        linkedin_link:"https://www.linkedin.com/in/tewelde-adhanom-18777595/",
        linkedin_name:" Tewelde Adhanom",
        profile_pic:"tewelde_pic.jpg"
},
{
    title:"Finance and Admin Manager",
    role:"finance and admin manager",
    fullname:"Melat Aregay",
    email:"Info@agoraconsulting-et.com",
    phonenumber:"+251-920-56-04-45",
    Address:"Grace Plaza 4th Floor, Bole, Addis Ababa",
    website:"https://agoraconsulting-et.com/",
    linkedin_link:"https://www.linkedin.com/in/melat-aregay-b98a20159?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app ",
    linkedin_name:"Melat Aregay",
    profile_pic:"melat_pic.jpg"
},
{
    title:"Operation Manager",
    role:"operation manager",
    fullname:"Walelign Dejen",
    email:"Info@agoraconsulting-et.com",
    phonenumber:"+251-930-69-69-12 ",
    Address:"Grace Plaza 4th Floor, Bole, Addis Ababa",
    website:"https://agoraconsulting-et.com/",
    linkedin_link:"null",
    linkedin_name:null,
    profile_pic:"walelegn_pic.jpg"
}
];
export default data;