import logo from './logo.svg';
import './App.css';
import ID_container from './ID_container';
import { Route, Routes } from 'react-router';

function App() {
  return (
    <div className='container-fluid'>
      <Routes>
      {/* <Route path="/" exact  element={<ID_container/>}/> */}

        <Route path="/team/:role" exact  element={<ID_container/>}/>
      </Routes>
     
    </div>
  );
}

export default App;
