import React, { useEffect, useState } from 'react'
import "./id_container.css";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { LinkedIn } from '@mui/icons-material';
import { useParams } from 'react-router';
import data from './Id_Data';
function ID_container() {
    const {role}=useParams();;
    const [user,setUser]=useState(
        {
            title:"",
            fullname:"",
            email:"",
            phonenumber:"",
            Address:"",
            website:"",
            linkedin_link:null,
            linkedin_name:null,
            profile_pic:""
        }
    );
    useEffect(()=>{
        const [value]= data.filter((emp)=>emp.role==role);
        setUser(value);
    },[])
  return (
    <div className='container-fluid p-0'>
        <div className='row'>
            <div className='col-sm-12 col-lg-6 mx-auto p-0 p-lg-1 '>
                <div className='id_container shadow rounded'>
                
                    <div className='logo-container'>
                        <img src='../Assets/Agora_logo.png' className='logo' alt='agora logo'/>
                    </div>
                    <div className='.profile_pic_container'>
                        <img src={`../Assets/${user.profile_pic}`} className='profile_pic rounded-circle' alt='agora logo'/>
                    </div>
                    <div className='info_container'>
                         <div>
                            <h2>{user.fullname}</h2>
                        </div>
                        <div>
                            <h3>{user.title}</h3>
                        </div>
                        < div className='details_container'>
                            <div>
                                <PhoneIcon/> <h5> {user.phonenumber}</h5>
                            </div>
                            <div>
                            <EmailIcon/> <a> <h5> {user.email}</h5></a>
                            </div>
                           
                            <div>
                            <LanguageIcon/> <a target="_blank" href={user.website}>  <h5>{user.website}</h5></a>
                            </div>
                            <div>
                            <LocationOnIcon/>  <h5> {user.Address}</h5>
                            </div>
                            <div>
                           { user.linkedin_link&&<><LinkedIn/>  <a target="_blank" href={user.linkedin_link}> <h5> {user.linkedin_name}</h5> </a></>}
                            </div>
                        </div>
                    </div>
                    <div class="wave-div mt-2"></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ID_container